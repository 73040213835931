import React from "react";

import Layout from "../components/layout/layout"

import SEO from "../components/seo";


const NDPVolunteerForm = () => {
  

  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO title="NDP Volunteer Form" />
        <iframe
          src="https://forms.office.com/Pages/ResponsePage.aspx?id=LldTO-My3kiR_RDMZhsmk1aQJsU-DiVCkgvds6Nz4ZxUQVI4Mk8wM0hVSUswTTJRWFhCNUNFVlc1Ui4u&embed=true"
          frameBorder="0"
          marginWidth="0"
          marginHeight="0"
          style={{
            width: "100%",
            height: "1900px",
            border: "none",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
          allowFullScreen
        />


      </Layout>
    </>
  );
};

export default NDPVolunteerForm;